<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Profilku </strong><small>Edit Profil</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateProfilku">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="username"
                label="Username"
                placeholder="Input username"
                readonly
                
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nickname"
                label="Nama Lengkap"
                placeholder="Input nama lengkap"
              />  
              </CCol> 
              <CCol sm="6">
              <CInput
                v-model="email"
                label="Email"
                placeholder="Input email"
              />
              </CCol>
            </CRow>            

                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/dashboard">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Profilku gagal diinput.
    </CModal>

    <CModal color="success" title="Sukses" :show.sync="mySukses" size="sm">
      <b>Sukses</b>! Data Profilku berhasil diperbaharui.
    </CModal>

    <CModal color="danger" title="Error" :show.sync="myError" size="sm">
      <b>Gagal</b>! Data Profilku gagal diperbaharui.
    </CModal>
   
  </div>
 
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      myError:false,
      mySukses:false,
      username : "",
      nickname : "",
      email : "",
      // foto : "",
      // foto_profil : "",
      // pass : "",
      // level : "",
      selected: [], // Must be an array reference!
      // kegiatan: [],
      show: true,
      id_user : "0",
      foto: "Pilih File Foto Profil",
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showProfilku();
  },
  methods: {    
    updateProfilku: function(event){
      
      let us = JSON.parse(this.$store.state.users);
      
      const profilku = { 
                          nickname: this.nickname, 
                          username: this.username,
                          email: this.email,
                          };
      // alert(JSON.stringify(user));
      axios.put(process.env.VUE_APP_BASE_URL+"user/"+this.id_user, profilku)
        .then((response) => {
            // console.log(response);
            console.log(response.data);
            if(response.status==200){
              this.mySukses = true;
              this.showProfilku();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showProfilku: function(event){
      // Simple POST request with a JSON body using axios
      let us = JSON.parse(this.$store.state.users);
      
      axios.get(process.env.VUE_APP_BASE_URL+"myprofile/"+us.id)
        .then((response) => {
              this.nickname=response.data.nickname;
              this.email=response.data.email;
              this.username=response.data.username;
              this.id_user = response.data.id_user;
            
        })
    }
  }
}
</script>